<template>
  <NavBar />
  <Header />
  <Section v-bind:sectionId=PORTFOLIO title="portfolio" v-bind:darkBackground="false" />
  <Section v-bind:sectionId=SERVICE title="services" v-bind:darkBackground="true" />
  <Section v-bind:sectionId=CONTACT title="contact" v-bind:darkBackground="false" />
  <Footer />
  <Copyright />
</template>

<script>
import NavBar from './components/static/NavBar.vue'
import Header from './components/static/Header.vue'
import Section from './components/Section.vue'
import Footer from './components/static/Footer.vue'
import Copyright from './components/static/Copyright.vue'
import { PORTFOLIO, SERVICE, CONTACT } from './js/constants.js'
// import jquery for ./js/jquery_scripts
import 'jquery'
import './js/jquery_scripts'

export default {
  name: 'App',
  components: {
    NavBar,
    Header,
    Section,
    Footer,
    Copyright
  },
  data(){
    return {
      PORTFOLIO,
      SERVICE,
      CONTACT
    };
  }
}
</script>

<style>
  @import './css/styles.css';
</style>
