<template>
  <div class="row">
    <div class="col-lg-4 ms-auto">
      <p class="lead">Specs &amp; Documentation</p>
      <div class="divider-custom-line"></div>
      <ul>
        <li>requirements analysis</li>
        <li>software documentation</li>
        <li>application &amp; system design</li>
        <li>review &amp; troubleshooting</li>
        <li>configuration &amp; release management</li>
      </ul>
    </div>
    <div class="col-lg-4 me-auto">
      <p class="lead">Full Stack Development</p>
      <div class="divider-custom-line"></div>
      <ul>
        <li>dev with several programming languages</li>
        <li>end to end integration testing</li>
        <li>DevOps pipelines</li>
        <li>integrations with legacy systems</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service'
}
</script>
