<template>
  <h2 class="page-section-heading text-center text-capitalize" v-bind:class="titleColorWhite ? 'text-white' : ''">{{ title }}</h2>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    title: String,
    titleColorWhite: Boolean
  }
}
</script>
