<template>
  <!-- Copyright Section-->
  <div class="copyright py-4 text-center text-white">
    <div class="container">
      <small>Copyright © Daccodes Ltd 2020</small>
    </div>
  </div>
  <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)-->
  <div class="scroll-to-top d-lg-none position-fixed" style="display: none;">
    <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
      <svg class="svg-inline--fa fa-chevron-up fa-w-14" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
        <path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>
      </svg><!-- <i class="fa fa-chevron-up"></i> -->
    </a>
  </div>
</template>

<script>
export default {
  name: 'Copyright'
}
</script>
