<template>
  <section class="page-section" 
    v-bind:class="[darkBackground ? 'bg-primary text-white mb-0' : '', sectionId === PORTFOLIO ? PORTFOLIO : '']" 
    v-bind:id="sectionId">
    <div class="container">
      <Headline v-bind:title="title" v-bind:titleColorWhite="darkBackground"/>
      <Separator v-bind:separatorColorLight="darkBackground"/>
      <template v-if="sectionId === PORTFOLIO">
        <Portfolio />
      </template>
      <template v-else-if="sectionId === SERVICE">
        <Service />
      </template>
      <template v-else-if="sectionId === CONTACT">
        <Contact />
      </template>
    </div>
  </section>
</template>

<script>
import Headline from './Headline.vue'
import Separator from './Separator.vue'
import Portfolio from './static/Portfolio.vue'
import Service from './static/Service.vue'
import Contact from './static/Contact.vue'
import { PORTFOLIO, SERVICE, CONTACT } from '../js/constants.js'

export default {
  name: 'Section',
  components: {
    Headline,
    Separator,
    Portfolio,
    Service,
    Contact
  },
  props: {
    darkBackground: Boolean,
    sectionId: String,
    title: String,
    titleColorWhite: Boolean
  },
  data(){
    return {
      PORTFOLIO,
      SERVICE,
      CONTACT
    };
  }
}
</script>
