<template>
  <div class="row">
    <div class="col-lg-5 mx-auto">
      <form id="contactForm" name="sentMessage" novalidate="novalidate">
        <div class="control-group">
          <div class="form-group floating-label-form-group controls mb-0 pb-2">
            <input class="form-control text-center" id="email" type="email" readonly value="info@daccodes.com">
          <div class="help-block"></div></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>
